import {isMobileDevice} from '@/app/_lib/csr-helpers'
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import Image from 'next/image'
import Link from 'next/link'
import BaseImage from 'tofisa-package/dist/components/Images/BaseImage'
import styles from './styles.module.scss'

export const Story = ({link, media, title}: {link: string; media: string; title: string}) => {
  const isMobile = isMobileDevice()
  return (
    <div className='d-flex flex-column align-items-center perStorySpace iconCursor text-center'>
      <Link href={link} className={styles['title']}>
        <div
          className={` ${
            !media && 'd-flex justify-content-center align-items-center bg-background'
          }`}
        >
          {!!media ? (
            <Image
              src={media}
              alt={title}
              width={isMobile ? 70 : 100}
              height={isMobile ? 70 : 100}
            />
          ) : (
            <BaseImage logo='/v2/Assets/Icons/NullStory.svg' alt='tofisa-emptyStory' />
          )}
        </div>

        <UI_Typography variant={isMobile ? 'Medium/Med12' : 'Regular/Reg14'} className='mt-1'>
          {title ?? ''}
        </UI_Typography>
      </Link>
    </div>
  )
}
