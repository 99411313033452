'use client'

import {HomeListDataItemsType} from '@/services/lib/types/home.type'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/navigation'
import TextComponent from 'tofisa-package/dist/components/TextComponent/TextComponent'

export const Box = ({homeBox, pageTitle}: {homeBox: HomeListDataItemsType; pageTitle: string}) => {
  const router = useRouter()

  return (
    <div className='d-flex'>
      {homeBox?.map(({link, media, title}, index) => (
        <div key={title} className='col-4 col-md-3' onClick={() => router.push(link)}>
          <Link href={link}>
            <div style={{backgroundColor: '#fff'}} className={`me-1 perBoxDetail h-100`}>
              <Image
                src={media}
                alt={title || pageTitle}
                priority={index === 0}
                loading={index === 0 ? 'eager' : 'lazy'}
                className='homeBoxImgClass lazyload'
              />
              <TextComponent
                txtComponent={title}
                txtClass='col-11 perBoxTitleClass body-semibold lazyload'
              />
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}
