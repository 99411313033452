'use client'

import {pushDefultPageView} from '@/services/dataLayer'
import {usePathname} from 'next/navigation'
import {useEffect} from 'react'

const PageProvider = ({slug}: {slug?: string}) => {
  const pathname = usePathname()

  useEffect(() => {
    pushDefultPageView({
      pageType: slug ?? 'home',
      pageUri: pathname as string,
    })
  }, [])

  return <></>
}

export default PageProvider
