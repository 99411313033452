'use client'

import {HomeListDataItemsType} from '@/services/lib/types/home.type'
import 'swiper/css'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Story} from './Story'
import styles from './styles.module.scss'
import {Fragment} from 'react'

export default ({
  storyItems,
  isMobile,
  pageTitle,
}: {
  storyItems: HomeListDataItemsType
  isMobile: boolean
  pageTitle: string
}) => {
  return (
    <Fragment>
      {isMobile ? (
        storyItems.map((story) => (
          <Story key={story.media} {...story} title={story.title || pageTitle} />
        ))
      ) : (
        <Swiper slidesPerView={10} className={styles['swiper']}>
          {storyItems.map((story) => (
            <SwiperSlide key={story.title} className={styles['swiper-slide-desktop']}>
              <Story {...story} title={story.title || pageTitle} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Fragment>
  )
}
