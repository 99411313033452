'use client'

import {HomeListDataItemsType} from '@/services/lib/types/home.type'
import Image from 'next/image'
import Link from 'next/link'
import {useRouter} from 'next/navigation'
import TextComponent from 'tofisa-package/dist/components/TextComponent/TextComponent'
import styles from './styles.module.scss'

export default ({
  bannerItems,
  pageTitle,
}: {
  bannerItems: HomeListDataItemsType
  pageTitle: string
}) => {
  const router = useRouter()
  return (
    <section className='d-flex flex-wrap w-100'>
      {bannerItems?.map(({width, link, title, media}, i) => (
        <div key={i} className={`col-${width}`}>
          <Link href={link}>
            <div
              onClick={() => router.push(link)}
              className='m-1 pb-1 bannerImageBase position-relative'
            >
              <Image
                className={styles['image']}
                src={media}
                alt={title || pageTitle}
                fill
                priority={i === 0}
                loading={i === 0 ? 'eager' : 'lazy'}
              />
              <TextComponent
                txtComponent={!!title ? title : ''}
                txtClass='position-absolute bannerTextClass text-white headline-1'
              />
            </div>
          </Link>
        </div>
      ))}
    </section>
  )
}
