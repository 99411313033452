import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/containers/pages/lib/components/banner/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/containers/pages/lib/components/desktop-version/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/containers/pages/lib/components/mobile-version/box/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/containers/pages/lib/components/page-provider/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/containers/pages/lib/components/stories/index.tsx");
