'use client'

import {HomeListDataType} from '@/services/lib/types/home.type'
import {useRouter} from 'next/navigation'
import Link from 'next/link'

// components
import {Container} from '@/components/container/Container'
import Image from 'next/image'
import dynamic from 'next/dynamic'

const Stories = dynamic(() => import('../stories'))
const Banner = dynamic(() => import('../banner'))

// styles
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'

export default ({item, pageTitle}: {item: HomeListDataType; pageTitle: string}) => {
  const router = useRouter()

  return (
    <Container>
      {item.type == 'story' ? (
        <div className='homeStoryBase d-flex pt-3'>
          <Stories storyItems={item.items} isMobile={false} pageTitle={pageTitle} />
        </div>
      ) : item.type == 'banner' ? (
        <Banner bannerItems={item.items} pageTitle={pageTitle} />
      ) : (
        <div>
          {item.items?.map(({title, link, media}: any) => (
            <div key={title} onClick={() => router.push(link)}>
              <Link href={link}>
                <div>
                  <Image src={media} alt={title} className='boxImgClass lazyload' />
                  <UI_Typography variant='Regular/Reg14'>{title}</UI_Typography>
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </Container>
  )
}
